import styled from "styled-components";

export const List = styled.ul`
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 25px;
`;

export const ListItem = styled.li`
    margin-bottom: 5px;
`;
