import * as React from "react";
import Master from "src/components/Master";
import PrivacyText from "src/components/privacyPage/PrivacyText";

const description =
    "Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist: DDM Druck & digitale Medien GmbH";
const metaKeywords =
    "Datenschutz, Datenschutzerklärung, Betroffenenrechte, Löschung bzw. Sperrung der Daten, Cookies, Datenschutzbestimmungen, Datenschutzbeauftragten";

export default (): React.ReactNode => (
    <Master title={"Datenschutzerklärung"} metaDescription={description} openGraphDescription={description} metaKeywords={metaKeywords}>
        <PrivacyText />
    </Master>
);
