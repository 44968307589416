import * as React from "react";
import ContentWrapper from "src/components/common/ContentWrapper";
import LightenBackground from "src/components/common/LightenBackground";
import Link from "src/components/common/Link";
import Text from "src/components/common/Text";
import Section from "src/components/privacyPage/common/Section";

import * as sc from "./PrivacyText.sc";

const Header = () => (
    <Section title="Datenschutzerklärung" first>
        <Text disableMargin>
            Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist: DDM Druck & digitale
            Medien GmbH
        </Text>
    </Section>
);

const YourRights = () => (
    <Section title="Ihre Betroffenenrechte">
        <Text>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</Text>
        <sc.List>
            <sc.ListItem>
                <Text disableMargin>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>Berichtigung unrichtiger personenbezogener Daten,</Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>Löschung Ihrer bei uns gespeicherten Daten,</Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>
                    Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
                </Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>
                    Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.
                </Text>
            </sc.ListItem>
        </sc.List>
        <Text disableMargin>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</Text>
    </Section>
);

const DataProcessing: React.FC = () => (
    <Section title="Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte">
        <Text>
            Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer
            persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte
            weiter, wenn:
        </Text>
        <sc.List>
            <sc.ListItem>
                <Text disableMargin>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</Text>
            </sc.ListItem>
            <sc.ListItem>
                <Text disableMargin>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</Text>
            </sc.ListItem>
        </sc.List>
        <Text disableMargin>
            die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes
            schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.
        </Text>
    </Section>
);

const DeletingOfData: React.FC = () => (
    <Section title="Löschung bzw. Sperrung der Daten">
        <Text disableMargin>
            Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange,
            wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen
            Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und
            entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
        </Text>
    </Section>
);

const Cookies: React.FC = () => (
    <Section title="Cookies">
        <Text disableMargin>Diese Website verwendet keine Cookies.</Text>
    </Section>
);

const Encryption: React.FC = () => (
    <Section title="SSL-Verschlüsselung">
        <Text disableMargin>
            Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende
            Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </Text>
    </Section>
);

const PolicyChanges: React.FC = () => (
    <Section title="Änderung unserer Datenschutz&shy;bestimmungen">
        <Text disableMargin>
            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch
            gilt dann die neue Datenschutzerklärung.
        </Text>
    </Section>
);

const Questions: React.FC = () => (
    <Section title="Fragen an den Datenschutz&shy;beauftragten" last>
        <Text>
            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz
            verantwortliche Person in unserer Organisation:
        </Text>
        <Text disableMargin>
            Diese Datenschutzerklärung wurde mit der Hilfe von{" "}
            <Link href={"https://www.activemind.de/datenschutz/datenschutzhinweis-generator/"} target={"_blank"}>
                Datenschutzerklärungs-Generator der activeMind AG
            </Link>{" "}
            und{" "}
            <Link href={"https://www.datenschutzbeauftragter-info.de/"} target={"_blank"}>
                www.datenschutzbeauftragter-info.de
            </Link>{" "}
            erstellt.
        </Text>
    </Section>
);

const PrivacyText: React.FC = () => (
    <>
        <ContentWrapper>
            <Header />
            <YourRights />
            <DataProcessing />
            <DeletingOfData />
        </ContentWrapper>
        <LightenBackground>
            <ContentWrapper>
                <Cookies />
                <Encryption />
                <PolicyChanges />
                <Questions />
            </ContentWrapper>
        </LightenBackground>
    </>
);

export default PrivacyText;
