import * as React from "react";
import styled from "styled-components";

import { colors } from "../../helpers";

const Link = styled.a`
    color: ${colors.link.default};
    text-decoration: underline;

    :hover {
        color: ${colors.link.hover};
    }
`;

export default Link;
