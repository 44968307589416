import { breakpoints } from "src/helpers";
import styled from "styled-components";

export const Root = styled.div``;

export const Content = styled.section`
    padding-bottom: 15px;
    padding-top: 15px;

    @media (min-width: ${breakpoints.medium}px) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
`;

export const HeadlineWrapper = styled.div`
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.medium}px) {
        margin-bottom: 15px;
    }
`;
