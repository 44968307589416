import * as React from "react";

import Separator from "../../common/Separator";
import Text, { variants } from "../../common/Text";
import * as sc from "./Section.sc";

interface Props {
    title: string;
    children: React.ReactNode;
    first?: boolean;
    last?: boolean;
}

const Section: React.FC<Props> = ({ title, children, first = false, last = false }) => (
    <sc.Root>
        <sc.Content>
            <sc.HeadlineWrapper>
                <Text disableMargin variant={first ? variants.headline1 : variants.headline2}>
                    {title}
                </Text>
            </sc.HeadlineWrapper>
            {children}
        </sc.Content>
        {!last && <Separator />}
    </sc.Root>
);

export default Section;
